/* Pulse Grow */
@include keyframes(#{$nameSpace}-pulse-grow) {
	to {
		@include prefixed(transform, scale(1.1));
	}
}

@mixin pulse-grow {
	@include hacks();

	&:hover,
	&:focus,
	&:active {
		@include prefixed(animation-name, #{$nameSpace}-pulse-grow);
		@include prefixed(animation-duration, $mediumDuration);
		@include prefixed(animation-timing-function, linear);
		@include prefixed(animation-iteration-count, infinite);
		@include prefixed(animation-direction, alternate);
	}
}
