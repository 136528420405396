/* Glow */
@mixin glow {
	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);
	@include prefixed(transition-property, box-shadow);

	&:hover,
	&:focus,
	&:active {
		box-shadow: 0 0 8px $shadowColor;
	}
}
