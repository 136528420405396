/* Bubble Bottom */
@mixin bubble-bottom {
	@include hacks();
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		z-index: -1;
		content: '';
		border-style: solid;
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
		left: calc(50% - #{$tipWidth});
		bottom: 0;
		border-width: $tipHeight $tipWidth 0 $tipWidth;
		border-color: $tipColor transparent transparent transparent;
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		@include prefixed(transform, translateY($tipHeight));
	}
}
