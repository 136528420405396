/* Border Fade */
@mixin border-fade {
	$borderWidth: 4px;

	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);
	@include prefixed(transition-property, box-shadow);
	box-shadow:
			inset 0 0 0 $borderWidth $primaryColor,
			0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */

	&:hover,
	&:focus,
	&:active {
		box-shadow:
			inset 0 0 0 $borderWidth $activeColor,
			0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */
	}
}
