/* Pulse */
@include keyframes(#{$nameSpace}-pulse) {
	25% {
		@include prefixed(transform, scale(1.1));
	}

	75% {
		@include prefixed(transform, scale(.9));
	}
}

@mixin pulse {
	@include hacks();

	&:hover,
	&:focus,
	&:active {
		@include prefixed(animation-name, #{$nameSpace}-pulse);
		@include prefixed(animation-duration, 1s);
		@include prefixed(animation-timing-function, linear);
		@include prefixed(animation-iteration-count, infinite);
	}
}
