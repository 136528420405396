.introduction {
  margin-top: 120px;
  background-color: #f1f1f1;
  @media(max-width: 510px) {
    margin-top: 0px;
    padding: 0px 30px;
  }
  .col-md-12 {
    padding: 30px 0px;
    color: #14224f;
    h1 {
      font-weight: bold;
      margin-top: 0px;
    }
    p {
      margin: 0px;
    }
  }
}

.se-container{
	display: block;
	width: 100%;
	overflow: hidden;
}
.se-slope{
	margin: 0 -50px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;

    @media(max-width: 991px) {
      -webkit-transform: rotate(0deg) !important;
      -moz-transform: rotate(0deg) !important;
      -o-transform: rotate(0deg) !important;
      -ms-transform: rotate(0deg) !important;
      transform: rotate(0deg) !important;
      margin: 0px !important;
      padding: 0px !important;
      margin-top: 0px !important;
    }

  .container {
      -webkit-transform: rotate(3deg) !important;
      -moz-transform: rotate(3deg) !important;
      -o-transform: rotate(3deg) !important;
      -ms-transform: rotate(3deg) !important;
      transform: rotate(3deg) !important;
      @media(max-width: 991px) {
        -webkit-transform: rotate(0deg) !important;
        -moz-transform: rotate(0deg) !important;
        -o-transform: rotate(0deg) !important;
        -ms-transform: rotate(0deg) !important;
        transform: rotate(0deg) !important;
        margin: 0px;
      }
  }
  &--content {
    &--sub-title {
      font-size: 18px;
      padding: 20px 0px;
    }
    &--list {
      li {
        padding-bottom: 10px;
        span {
          &:first-child {
            display: inline-block;
            width: 5%;
          }
          &:last-child {
            display: inline-block;
            width: 94%;
          }
        }
      }
    }
  }
  &--title {
    @media(max-width: 991px) {
        -webkit-transform: rotate(0deg) !important;
        -moz-transform: rotate(0deg) !important;
        -o-transform: rotate(0deg) !important;
        -ms-transform: rotate(0deg) !important;
        transform: rotate(0deg) !important;
        margin-top: 0px !important;
    }
    img {
      @media(max-width: 991px) {
        margin-top: 10px;
        margin-bottom: 10px;
      } 
    }
  }
}
.se-slope:nth-child(odd){
	background: #e90089;
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	transform: rotate(-3deg);
	box-shadow: 0px 2px 3px rgba(0,0,0,0.4) inset;
}
.se-slope:nth-child(even){
	background: #000;
	-webkit-transform: rotate(2deg);
	-moz-transform: rotate(2deg);
	-o-transform: rotate(2deg);
	-ms-transform: rotate(2deg);
	transform: rotate(2deg);
	margin-top: -200px;
	box-shadow: 0px -1px 3px rgba(0,0,0,0.4);
}

@media screen and (max-width: 400px) {
	.se-slope:nth-child(odd) .se-content,
	.se-slope:nth-child(even) .se-content{
		padding-left:60px;
		padding-right:60px;
		
	}
}



.controlled-expenditure {
  &--title {  
    background-color: $ce-blue !important;
    padding: 135px;
    .se-slope--title {
      transform: rotate(-5deg);
      margin-top: -130px;
    }
  }
  &--content {
    background-color: #fff !important;
    padding: 102px;
    margin-top: -75px;
    &--header {
      margin: 30px 0px 15px 0px;
      background-color: $ce-blue;
      color: #fff;
      padding: 10px;
      h4 {
        margin: 0px;
      }
    }
    &--list {
      list-style: circle !important;
      margin-top: 30px;
    }
    ul {
      padding-left: 15px;
      list-style: none;
    }
  }
  &--images {
    h4 {
      color: $ce-blue;
    }
    img {
      margin: 30px auto 0px auto;
      max-width: 200px;
    }
  }
}
.se-slope2 {
    background: #e90089;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.4);
    margin-top: -4px;
    transform: rotate(-3deg);
}
.se-slope3 {
    background: #e90089 none repeat scroll 0 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4) inset;
    transform: rotate(2deg);
}
.personalised-fitness2 {
  &--title {  
    background-color: $green !important;
    padding: 135px;
    .se-slope--title {
      transform: rotate(2deg);
      margin-top: -132px;
      float: right;
      @media(max-width: 991px) {
        float: left;
      }
    }
  }
  &--content {
    .container {
      transform: rotate(-2deg) !important;
      @media(max-width: 991px) {
        transform: rotate(0deg) !important;
      }
    }
    background-color: #fff !important;
    padding: 80px;
    margin-top: -280px !important;
    @media(max-width:991px) {
      margin-top: 0px !important;
    }
    ul {
      padding-left: 15px;
      list-style: none;
    }
  }
}
.personalised-fitness {
  &--title {  
    background-color: $green !important;
    padding: 115px;
    .se-slope--title {
      transform: rotate(0deg);
      margin-top: -105px;
      float: right;
      @media(max-width: 991px) {
        float: left;
      }
    }
  }
  &--content {
    .container {
      transform: rotate(-2deg) !important;
      @media(max-width: 991px) {
        transform: rotate(0deg) !important;
      }
    }
    background-color: #fff !important;
    padding: 80px;
    margin-top: -200px !important;
    @media(max-width:991px) {
      margin-top: 0px !important;
    }
    ul {
      padding-left: 15px;
      list-style: none;
    }
  }
}

.targeted-solutions {
  &--title {  
    background-color: $orange !important;
    padding: 135px;
    &--title {
      margin-top: -155px !important;
    }
    @media(max-width: 991px) {
      height: 120px;
      img {
        height: 100px;
      }
    }
    .se-slope--title {
      transform: rotate(-5deg);
      margin-top: -140px;
    }
  }
  &--content {
    .container {
      transform: rotate(3deg);
    }
    background-color: #fff !important;
    padding: 80px;
    margin-top: -75px;
    ul {
      padding-left: 15px;
      list-style: none;
    }
  }
}

.section-image {
  margin-top: -102px;
  @media(max-width: 991px) {
    margin-top: 0px;
  }
}

.slick-slider {
  @media(max-width:991px) {
    max-width: 500px;
    margin: 30px auto;
  }
}

.slick-blue {
  border: 4px solid $ce_blue;
}

.slick-green {
  border: 4px solid $green;
}

.slick-orange {
  border: 4px solid $orange;
}

.slick-dots {
  bottom: 10px;
  li {
    button {
      &:before {
        font-size: 50px;
      }
    }
  }
}

.download-brochure-title, .request-a-sample-title, .contact-us-title {
  margin-bottom: 30px;
}

.download-brochure-form, .request-a-sample-form, .contact-us-form {
  label {
    width: 30%;
    color: #fff !important;
    @media(max-width:991px) {
      width: 100%;
    }
  }
  input {
    width: 68% !important;
    vertical-align: top;
    @media(max-width:991px) {
      width: 100% !important;
    }
  }
  .form-group {
    width: 100%;
    margin-bottom: 15px;
  }
}

.download-brochure-form {
  label {
    color: #6a6a6a !important;
  }
  &--image {
    margin: 0px auto;
  }
}

.request-a-sample {
  margin-top: 60px;
  background-color: #c5c5c5;
  padding: 30px 0px;
  &--image {
    @media(min-width: 991px) {
      margin-top: 100px;
    }
    @media(max-width: 990px) {
      margin: 30px auto 0px auto;
    }
  }
}

.contact-us {
  background-color: #14224f;
  padding: 30px 0px;
  &--image {
    @media(min-width: 991px) {
      margin-top: 150px;
    }
    @media(max-width: 990px) {
      margin: 30px auto 0px auto;
    }
  }
}

.enquiry-label {
  width: 100% !important;
}

.section-title {
    font-size: 24px !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    font-family: inherit !important;
    font-weight: 500 !important;
    line-height: 1.1 !important;
    color: inherit !important;
}

.homepage-case-studies {
  margin-top: -100px;
  margin-bottom: 100px;
  @media(max-width: 990px) {
      margin: 30px auto 0px auto;
  }
}

.g-recaptcha {
  float: right;
  margin-bottom: 15px;
}

.topBarTara{
  background-color:#eff0f1!important;
    background:url("../../../images/pages/taralay/cover.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position:bottom;
    display:table;
    height:500px;
    width:100%;
}
.topBarGTI{
  background-color:#eff0f1!important;
    background:url("../../../images/pages/gti/cover.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position:bottom;
    display:table;
    height:500px;
    width:100%;
}
.topBar70{
  background-color:#eff0f1!important;
    background:url("../../../images/pages/creation70/cover.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position:bottom;
    display:table;
    height:500px;
    width:100%;
}
.topBar55{
  background-color:#eff0f1!important;
    background:url("../../../images/pages/creation55/cover.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position:bottom;
    display:table;
    height:500px;
    width:100%;
}
@media(max-width: 991px) {
    .topBar55{
    background-position:0px 0px;
    background-attachment: initial;
    }
    .topBar70{
    background-position:0px 0px;
    background-attachment: initial;
    }
    .topBarGTI{
    background-position:0px 0px;
    background-attachment: initial;
    }
    .topBarTara{
    background-position:0px 0px;
    background-attachment: initial;
    }
}
iframe{
  margin-top:20px;
}