.footer {
  padding: 30px 0px;
  &--logo {
    max-width: 250px;
    @media(max-width: 751px) {
      margin: 0px auto;
    }
  }
  .col-md-8 {
    p {
      text-align: right;
      @media(max-width: 751px) {
        text-align: center;
      }
    }
  }
}

.social-icons {
  text-align: right;
  padding-bottom: 10px;
  @media(max-width: 751px) {
  text-align: center;
  padding-top: 10px;
  }
  img {
    display: inline-block;
    padding-left: 15px;
    width: 65px;
  }
}
