/* Pulse Shrink */
@include keyframes(#{$nameSpace}-pulse-shrink) {
	to {
		@include prefixed(transform, scale(.9));
	}
}

@mixin pulse-shrink {
	@include hacks();

	&:hover,
	&:focus,
	&:active {
		@include prefixed(animation-name, #{$nameSpace}-pulse-shrink);
		@include prefixed(animation-duration, $mediumDuration);
		@include prefixed(animation-timing-function, linear);
		@include prefixed(animation-iteration-count, infinite);
		@include prefixed(animation-direction, alternate);
	}
}
