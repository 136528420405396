@import "vendor/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Lato);

// COLOURS

$blue: #14224f;
$ce_blue: #009cd6;
$green: #9fc54d;
$orange: #ff9b50;
$dark_grey: #6a6a6a;

// GENERAL

.container {
  @media(max-width:991px) {
    width: 100%;
  }
}

* {
  font-family: 'Lato', sans-serif;
  color: $dark_grey;
}

p, li {
  font-size: 16px;
}

.row-height {
  @media(min-width: 991px) {
    display: flex;
  }
}

.full-width {
  width: 100% !important;
}

.btn-homepage {
  width: 85%;
  float: right !important;
  @media(max-width: 991px) {
    width: 100% !important;
  }
}

.btn-width {
  width: 85% !important;
}

.btn-white {
  @extend .btn-success;
  background-color: #fff;
  border-color: #fff;
  border-radius: 0px;
  color: #14224f;
  font-weight: bold;
  &:hover {
    background-color: #ff9c49;
    border-color: #ff9c49;
    color: #fff;
  }
}

.btn-orange {
  @extend .btn-success;
  background-color: #ff9c49;
  border-color: #ff9c49;
  border-radius: 0px;
  color: #fff;
  font-weight: bold;
  &:hover {
    background-color: #14224f;
    border-color: #14224f;
    color: #fff;
  }
}

.btn-blue {
  @extend .btn-success;
  background-color: #009cd6;
  border-color: #009cd6;
  border-radius: 0px;
  color: #fff;
  font-weight: bold;
  &:hover {
    background-color: #ff9c49;
    border-color: #ff9c49;
    color: #fff;
  }
}

.marker {
    padding-top: 100px;
    margin-top: -100px;
}

.margin-auto {
  margin: 0px auto;
}

// LAYOUTS
@import "layouts/header";
@import "layouts/footer";

// PAGES
@import "pages/index";
@import "pages/case-studies";
