/* Fade */
@mixin fade {
	@include hacks();
	overflow: hidden;
	@include prefixed(transition-duration, $mediumDuration);
	@include prefixed(transition-property, "color, background-color");

	&:hover,
	&:focus,
	&:active {
		background-color: $activeColor;
		color: white;
	}
}
