/* Buzz */
@include keyframes(#{$nameSpace}-buzz) {
	50% {
		@include prefixed(transform, translateX(3px) rotate(2deg));
	}

	100% {
		@include prefixed(transform, translateX(-3px) rotate(-2deg));
	}
}

@mixin buzz {
	@include hacks();

	&:hover,
	&:focus,
	&:active {
		@include prefixed(animation-name, #{$nameSpace}-buzz);
		@include prefixed(animation-duration, .15s);
		@include prefixed(animation-timing-function, linear);
		@include prefixed(animation-iteration-count, infinite);
	}
}
