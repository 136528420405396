/* Icon Pulse */
@include keyframes(#{$nameSpace}-icon-pulse) {
	25% {
		@include prefixed(transform, scale(1.3));
	}

	75% {
		@include prefixed(transform, scale(.8));
	}
}

@mixin icon-pulse {
	@include hacks();
	position: relative;
	padding-right: #{$spaceBetweenTextAndArrows};

	&:before {
		content: "\f015";
		position: absolute;
		right: 1em;
		padding: 0 1px;
		font-family: FontAwesome;
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		&:before {
			@include prefixed(animation-name, #{$nameSpace}-icon-pulse);
			@include prefixed(animation-duration, 1s);
			@include prefixed(animation-timing-function, linear);
			@include prefixed(animation-iteration-count, infinite);
		}
	}
}
