/* Bubble Float Bottom */
@mixin bubble-float-bottom {
	@include hacks();
	position: relative;
	@include prefixed(transition-duration, $mediumDuration);
	@include prefixed(transition-property, transform);

	&:before {
		position: absolute;
		z-index: -1;
		content: '';
		left: calc(50% - #{$tipWidth});
		bottom: 0;
		border-style: solid;
		border-width: $tipHeight $tipWidth 0 $tipWidth;
		border-color: $tipColor transparent transparent transparent;
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
	}

	&:hover,
	&:focus,
	&:active {
		@include prefixed(transform, translateY(-($tipHeight)));

		&:before {
			@include prefixed(transform, translateY($tipHeight));
		}
	}
}
