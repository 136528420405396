.header {
    padding: 15px 0px;
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 9999999999;
    top: 0px;

  -webkit-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.75);

  @media(max-width: 510px) {
    position: relative;
  }

  &--logo {
    -webkit-transition: height 0.5s ease 0s;
    -moz-transition: height 0.5s ease 0s;
    -ms-transition: height 0.5s ease 0s;
    -o-transition: height 0.5s ease 0s;
    @media(max-width: 991px) {
      margin: 10px auto;
    }
  }
  &--info {
    text-align:right;
    padding-right: 20px;
    a {
      color: inherit;
    }
  }
  &--nav {
    list-style: none;
    text-align: center;
    position: absolute;
    bottom: 0px;
    @media(max-width: 991px) {
      padding-left: 0px;
      position: relative;
    }
    li {
      display: inline-block;
      padding: 0px 18px;
      @media(max-width: 991px) {
        padding: 0px 15px;
      }
      a {
        color: $blue;
        font-weight: bold;
        .fa {
          color: $blue;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.affix-top {
  .header--logo {
    height: 95px;
  }
}

.affix {
  .header--logo {
    height: 60px;
  }
}

.hvr-underline-from-left::before {
  background: $blue;
}

.productslide{
  height:300px;
}
.productslide p{
  padding:4px;
}
.productslide img{
  margin:auto;
}
.blueButton{
  border:0px;
  background-color:#009cd6;
  color:#FFF;
  padding:10px 20px;
  font-weight:bold;
  font-size:26px;
}
.greenButton{
  border:0px;
  background-color:#9fc54d;
  color:#FFF;
  padding:10px 20px;
  font-weight:bold;
  font-size:26px;
  float:right;
}
.orangeButton{
  border:0px;
  background-color:#ff9b50;
  color:#FFF;
  padding:10px 20px;
  font-weight:bold;
  font-size:26px;
}
.greenPara{
  color:#9ac31c;
}
.orangePara{
  color:#ff9b50;
}
.spaced{
  margin-bottom:20px;
  border-bottom:solid 4px #f8f8f8;
  padding-bottom:20px;
}
.quoteBox{
  background-color:#f8f8f8;
  padding:10px;
}
@media(min-width: 510px) {
  .topPad{
    padding-top:120px;
  }
}
@media(max-width: 991px) {
    .orangeButton, .greenButton, .blueButton{
      float:none;
      margin-bottom:20px;
    }
    .linkBtn{
      width:100%;
      text-align:center;
    }
    .case-studies--box{
      text-align:center;
    }
    .case-studies--box img{
      margin:auto;
    }
  }

.slantyslanty .se-slope.targeted-solutions--content{
  margin: -90px -73px;
}
