/* Bubble Top */
@mixin bubble-top {
	@include hacks();
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		z-index: -1;
		content: '';
		border-style: solid;
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
		left: calc(50% - #{$tipWidth});
		top: 0;
		border-width: 0 $tipWidth $tipHeight $tipWidth;
		border-color: transparent transparent $tipColor transparent;
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		@include prefixed(transform, translateY(-($tipHeight)));
	}
}
