.case-studies {
  margin-top: 15px;
  &--box {
    margin-bottom: 30px;
    &--title {
      font-size: 16px;
      color: $blue;
      padding-top: 10px;
      text-transform: uppercase;
    }
    &--social {
      line-height: 33px;
      font-size: 16px;
      text-align: right;
      a {
        padding: 0px 5px;
      }
    }
  }
}

.case-study {
  margin-top: 120px;
  &--content {
    &--title {
      margin-top: 0px;
      text-transform: uppercase;
    }
    &--social {
      font-size: 16px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 10px 0px;
      width: 100%;
      a {
        padding: 0px 5px;
        color: inherit;
      }
    }
    &--uploaded {
      display: inline-block;
      text-align: right;
      float: right;
    }
    &--view-more {
      margin-top: 15px;
    }
    img {
      padding: 15px 0px;
    }
  }
}

.breadcrumb {
  margin-top: 15px;
  background-color: #fff;
  padding-left: 0px;
  li {
    a {
      color: $blue;
    }
  }
}
