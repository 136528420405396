/* Hang */
@include keyframes(#{$nameSpace}-hang) {
	0% {
		@include prefixed(transform, translateY(8px));
	}
	50% {
		@include prefixed(transform, translateY(4px));
	}
	100% {
		@include prefixed(transform, translateY(8px));
	}
}

@include keyframes(#{$nameSpace}-hang-sink) {
	100% {
		@include prefixed(transform, translateY(8px));
	}
}

@mixin hang {
	@include hacks();

	&:hover,
	&:focus,
	&:active {
		@include prefixed(animation-name, "#{$nameSpace}-hang-sink, #{$nameSpace}-hang");
		@include prefixed(animation-duration, ".3s, 1.5s");
		@include prefixed(animation-delay, "0s, .3s");
		@include prefixed(animation-timing-function, "ease-out, ease-in-out");
		@include prefixed(animation-iteration-count, "1, infinite");
		@include prefixed(animation-fill-mode, "forwards");
		@include prefixed(animation-direction, "normal, alternate");
	}
}
