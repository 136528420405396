/* Bubble Float Right */
@mixin bubble-float-right {
	@include hacks();
	position: relative;
	@include prefixed(transition-duration, $mediumDuration);
	@include prefixed(transition-property, transform);

	&:before {
		position: absolute;
		z-index: -1;
		top: calc(50% - #{$tipHeight});
		right: 0;
		content: '';
		border-style: solid;
		border-width: $tipHeight 0 $tipHeight $tipWidth;
		border-color: transparent transparent transparent $tipColor;
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
	}

	&:hover,
	&:focus,
	&:active {
		@include prefixed(transform, translateX(-($tipWidth)));

		&:before {
			@include prefixed(transform, translateX($tipWidth));
		}
	}
}
